<template>
  <div id="dataCenter">
    <div class="top-info">
      <div class="shop-info">
        <img v-lazy="shopInfo.market_logo" alt/>
        <p>{{shopInfo.market_name}}</p>
      </div>

      <div class="deal-info">
        <p class="today-title">今日交易额（元）</p>
        <p class="today-num">{{dataInfo.TodayTurnover}}</p>
        <div class="deal-box">
          <div class="deal-item">
            <p>今日订单</p>
            <span>{{dataInfo.TodayOrdersNum}}</span>
          </div>

          <div class="deal-item">
            <p>昨日订单</p>
            <span>{{dataInfo.YesterDayNum}}</span>
          </div>

          <div class="deal-item">
            <p>七日订单</p>
            <span>{{dataInfo.SevenDaysOrders}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="time-box">
      <div class="near-time-box">
        <div
          :class="nearIndex === index ? 'near-item active' : 'near-item'"
          v-for="(item, index) in nearTime"
          :key="index"
          @click="changeNearTime(index)"
        >{{item}}
        </div>
      </div>

      <div class="time-choice-box">
        <p @click="dateShow('start')">{{nearStartTime}}</p>
        <img src="@images/arrow-bottom.png" alt="">
        <span>至</span>
        <p @click="dateShow('end')">{{nearEndTime}}</p>
        <img src="@images/arrow-bottom.png" alt="">
      </div>
    </div>

    <div class="data-box">
      <div class="data-title" @click="showPicker = true">
        <p>{{typeVal}}</p>
        <img src="@images/arrow-bottom.png" alt/>
      </div>

      <div id="myChart" style="width:100%; height:200px;"></div>
    </div>

    <!-- <van-popup v-model="showTime" position="bottom">
      <van-datetime-picker
        type="date"
        :max-date="maxStartDate"
        v-model="currentStartDate"
        @confirm="onStartConfirm"
        @cancel="showStartTime = false"
      />
    </van-popup>-->

    <van-popup v-model="showTime" position="bottom">
      <van-datetime-picker
        type="date"
        :max-date="maxDate"
        :min-date="minDate"
        v-model="currentDate"
        @confirm="onDateConfirm"
        @cancel="showTime = false"
      />
    </van-popup>

    <!-- <van-popup v-model="showEndTime" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minEndDate"
        :max-date="maxEndDate"
        v-model="currentEndDate"
        @confirm="onEndConfirm"
        @cancel="showEndTime = false"
      />
    </van-popup>-->
    <p class="bottom-tips">由北京熙虎科技有限公司提供技术支持</p>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
  import {dataCenter} from "../api/requist";

  export default {
    components: {},
    props: [],
    data() {
      return {
        type: "start",
        currentDate: "",
        shopInfo: {},
        dataInfo: {},
        showPicker: false,
        typeVal: "交易金额",
        columns: ["交易金额", "访问人数", "订单数量"],
        currentStartDate: "",
        currentEndDate: "",
        maxDate: new Date(),
        minDate: new Date(),
        minEndDate: "",
        maxStartDate: "",
        maxEndDate: new Date(),
        showEndTime: false,
        nearStartTime: "",
        nearEndTime: "",
        showTime: false,
        nearIndex: 0,
        nearTime: ["近7天", "近30天"]
      };
    },
    created() {
    },
    mounted() {
      this.init();
      this.getInfo();
    },
    watch: {},
    computed: {},
    methods: {
      getOption(unit, xAxis, data) {
        return {
          grid: {
            top: "5%",
            left: "2%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          tooltip: {
            trigger: "axis",
            formatter: `{b}\t{c}${unit}`,
            position: function (point, params, dom, rect, size) {
              //其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
              var x = point[0]; //
              var y = point[1];
              var viewWidth = size.viewSize[0];
              var viewHeight = size.viewSize[1];
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1] || 0;
              var posX = 0; //x坐标位置
              var posY = 0; //y坐标位置

              if (x < boxWidth) {
                //左边放不开
                posX = 5;
              } else {
                //左边放的下
                posX = x - boxWidth;
              }

              if (y < boxHeight) {
                //上边放不开
                posY = 5;
              } else {
                //上边放得下
                posY = y - boxHeight;
              }

              return [posX, posY];
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xAxis,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#909399", //更改坐标轴文字颜色
                fontSize: "24rpx" //更改坐标轴文字大小
              }
            }
          },
          yAxis: {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#909399", //更改坐标轴文字颜色
                fontSize: "24rpx" //更改坐标轴文字大小
              }
            }
          },
          series: [
            {
              color: "#F5F7FA", //改变区域颜色
              data: data,
              type: "line",
              areaStyle: {},
              itemStyle: {
                normal: {
                  color: "rgba(255,103,55,0.15)",
                  lineStyle: {
                    color: "#FF6737"
                  }
                }
              }
            }
          ]
        };
      },

      dateShow(type) {
        if (type === "start") {
          this.maxDate = this.currentEndDate;
          this.minDate = new Date("2010/10/10");
          this.currentDate = new Date(this.nearStartTime.replace(/-/g, "/"));
          this.$nextTick(() => {
            this.currentDate = new Date(this.nearStartTime.replace(/-/g, "/"));
            this.showTime = true;
          });
          // console.log(this.maxDate, this.minDate, new Date(this.currentDate));
        } else {
          // console.log(this.currentStartDate, 909, new Date());
          this.minDate = this.currentStartDate;
          this.maxDate = new Date();
          this.$nextTick(() => {
            this.currentDate = new Date(this.nearEndTime.replace(/-/g, "/"));
            this.showTime = true;
          });
        }

        this.type = type;
      },

      onDateConfirm(time) {
        if (this.type === "start") {
          this.currentStartDate = time;
          this.nearStartTime = `${this.getCurrentDate(0, time)[0]}-${
            this.getCurrentDate(0, time)[1]
          }-${this.getCurrentDate(0, time)[2]}`;
        } else {
          this.currentEndDate = time;
          this.nearEndTime = `${this.getCurrentDate(0, time)[0]}-${
            this.getCurrentDate(0, time)[1]
          }-${this.getCurrentDate(0, time)[2]}`;
        }

        this.getInfo();
        this.$nextTick(() => {
          this.showTime = false;
        });
      },

      onConfirm(value) {
        this.typeVal = value;
        this.getInfo();
        this.showPicker = false;
      },

      async getInfo() {
        let formData = new FormData();
        formData.append("merch_id", localStorage.getItem("merch_id"));
        formData.append("start_time", this.nearStartTime);
        formData.append("end_time", this.nearEndTime);
        formData.append("serch_type", this.columns.indexOf(this.typeVal) + 1);
        let res = await dataCenter(formData);
        var unit;
        if (this.columns.indexOf(this.typeVal) === 0) {
          unit = "元";
        } else if (this.columns.indexOf(this.typeVal) === 1) {
          unit = "人";
        } else if (this.columns.indexOf(this.typeVal) === 2) {
          unit = "单";
        }
        if (res.code === 200) {
          this.shopInfo = res.data.MerchInfo;
          this.dataInfo = res.data.MerchOrders;
          let myChart = this.$echarts.init(document.getElementById("myChart"));
          myChart.setOption(
            this.getOption(
              unit,
              res.data.DataCenterInfo.merge_time,
              res.data.DataCenterInfo.merge_value
            )
          );
        }
      },

      changeNearTime(index) {
        this.nearIndex = index;
        if (this.nearIndex == 0) {
          this.nearStartTime = `${this.getCurrentDate(7)[0]}-${
            this.getCurrentDate(7)[1]
          }-${this.getCurrentDate(7)[2]}`;
          this.nearEndTime = `${this.getCurrentDate(0)[0]}-${
            this.getCurrentDate(0)[1]
          }-${this.getCurrentDate(0)[2]}`;
          this.currentStartDate = new Date(this.nearStartTime);
          this.currentEndDate = new Date(this.nearEndTime);
        } else if (this.nearIndex == 1) {
          this.nearStartTime = `${this.getCurrentDate(30)[0]}-${
            this.getCurrentDate(30)[1]
          }-${this.getCurrentDate(30)[2]}`;
          this.nearEndTime = `${this.getCurrentDate(0)[0]}-${
            this.getCurrentDate(0)[1]
          }-${this.getCurrentDate(0)[2]}`;
          this.currentStartDate = new Date(this.nearStartTime.replace(/-/g, "/"));
          this.currentEndDate = new Date(this.nearEndTime.replace(/-/g, "/"));
        }
        this.getInfo();
      },

      init() {
        this.nearStartTime = `${this.getCurrentDate(7)[0]}-${
          this.getCurrentDate(7)[1]
        }-${this.getCurrentDate(7)[2]}`;
        this.nearEndTime = `${this.getCurrentDate(0)[0]}-${
          this.getCurrentDate(0)[1]
        }-${this.getCurrentDate(0)[2]}`;
        this.currentStartDate = new Date(this.nearStartTime.replace(/-/g, "/"));
        this.currentEndDate = new Date(this.nearEndTime.replace(/-/g, "/"));
        this.minEndDate = this.currentStartDate;
        this.maxEndDate = new Date();
        this.maxStartDate = this.currentEndDate;
      },

      // onStartConfirm(time) {
      //   this.currentStartDate = time;

      //   this.nearStartTime = `${this.getCurrentDate(0, time)[0]}-${
      //     this.getCurrentDate(0, time)[1]
      //   }-${this.getCurrentDate(0, time)[2]}`;

      //   this.minEndDate = this.currentStartDate;
      //   // console.log(this.currentEndDate,9)
      //   this.getInfo();
      //   this.showStartTime = false;
      // },
      // onEndConfirm(time) {
      //   this.nearEndTime = `${this.getCurrentDate(0, time)[0]}-${
      //     this.getCurrentDate(0, time)[1]
      //   }-${this.getCurrentDate(0, time)[2]}`;
      //   this.maxStartDate = this.currentEndDate;
      //   this.getInfo();
      //   this.showEndTime = false;
      // },
      // 获取当前时间/ 或者前几天 /确定时间
      getCurrentDate(otherDay = 0, timer = new Date()) {
        var timeStr = "-";
        var curDate = new Date(timer.getTime() - otherDay * 60 * 60 * 24 * 1000);
        var curYear = curDate.getFullYear(); //获取完整的年份(4位,1970-????)
        // var curMonth = curDate.getMonth() + 1 > 10 ? curDate.getMonth() + 1 : '0' + (curDate.getMonth() + 1); //获取当前月份(0-11,0代表1月)
        var curMonth = curDate.getMonth() + 1 > 10 ? curDate.getMonth() + 1 : '' + (curDate.getMonth() + 1); //获取当前月份(0-11,0代表1月)
        var curDay = curDate.getDate() >= 10 ? curDate.getDate() : '0' + curDate.getDate(); //获取当前日(1-31)
        var curWeekDay = curDate.getDay(); //获取当前星期X(0-6,0代表星期天)
        var curHour = curDate.getHours(); //获取当前小时数(0-23)
        var curMinute = curDate.getMinutes(); // 获取当前分钟数(0-59)
        var curSec = curDate.getSeconds(); //获取当前秒数(0-59)
        var Current =
          curYear +
          timeStr +
          curMonth +
          timeStr +
          curDay +
          " " +
          curHour +
          ":" +
          curMinute +
          ":" +
          curSec;
        return [curYear, curMonth, curDay, curHour, curMinute, curSec];
      }
    }
  };
</script>
<style lang="scss">
  @import "../common/css/theme.scss";

  #dataCenter {
    padding-bottom: 14.4 * 3.75px;
    box-sizing: border-box;
    min-height: 100vh;
    background: rgba(245, 247, 250, 1);
    overflow: hidden;

    #myChart {
      margin-top: 12px;
      canvas,
      div {
        margin: 0 auto 2.13 * 3.75px !important;
      }
    }

    .van-picker__confirm {
      color: $theme-color;
    }

    .top-info {
      padding: 12px 16px 24px 16px;
      background: rgba(255, 255, 255, 1);

      .shop-info {
        margin-bottom: 2.67 * 3.75px;
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
          height: 8.8 * 3.75px;
          width: 8.8 * 3.75px;
          border-radius: 50%;
        }

        p {
          font-weight: bold;
          color: rgba(48, 49, 51, 1);
          font-size: 4.27 * 3.75px;
        }
      }

      .deal-info {
        padding: 24px 16px;
        box-sizing: border-box;
        width: calc(100vw - 16px * 2);
        margin: 0 auto;
        background: $theme-color;
        border-radius: 1 * 3.75px;

        .today-title {
          font-size: 4.27 * 3.75px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 3.2 * 3.75px;
          text-align: left;
        }

        .today-num {
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          font-size: 5.33 * 3.75px;
          margin-bottom: 5.33 * 3.75px;
          text-align: left;
        }

        .deal-box {
          display: flex;
          align-items: center;

          .deal-item {
            margin-right: 14.93 * 3.75px;
            font-size: 3.2 * 3.75px;
            color: #fff;
            text-align: left;

            &:last-child {
              margin-right: 0;
            }

            span {
              margin-top: 1.6 * 3.75px;
              display: block;
            }
          }
        }
      }
    }

    .time-box {
      margin-top: 12px;
      padding-top: 4.8 * 3.75px;
      box-sizing: border-box;
      width: 100%;
      height: 25.87 * 3.75px;
      background: rgba(255, 255, 255, 1);

      .near-time-box {
        display: flex;
        align-items: center;
        justify-content: center;

        .near-item {
          text-align: center;
          width: 21.33 * 3.75px;
          height: 8.53 * 3.75px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(96, 98, 102, 1);
          font-size: 3.73 * 3.75px;
          font-weight: 400;
          line-height: 8.53 * 3.75px;
          color: rgba(144, 147, 153, 1);
          border-radius: 5 * 3.75px;
          box-sizing: border-box;

          &:first-child {
            margin-right: 4.8 * 3.75px;
          }

          &.active {
            border: none;
            background: $theme-color;
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      .time-choice-box {
        display: flex;
        margin-top: 3.2 * 3.75px;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        p {
          margin-right: 1.6 * 3.75px;
          font-size: 14px;
          color: rgba(48, 49, 51, 1);
        }

        img {
          height: 4px;
          width: 8px;
        }

        span {
          margin: 0 3.2 * 3.75px;
          color: rgba(144, 147, 153, 1);
          font-size: 3.2 * 3.75px;
        }
      }
    }

    .data-box {
      margin-top: 12px;
      padding: 16px 0;
      box-sizing: border-box;
      width: 100%;
      background: rgba(255, 255, 255, 1);

      .data-title {
        padding-left: 4.8 * 3.75px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        p {
          font-size: 4.27 * 3.75px;
          font-weight: bold;
          color: rgba(48, 49, 51, 1);
          margin-right: 1.6 * 3.75px;
        }

        img {
          width: 2.13 * 3.75px;
          height: 1.07 * 3.75px;
        }
      }
    }

    .black {
      background-color: #fff;
      height: 6.13 * 3.75px;
    }

    .bottom-tips {
      padding: 9.97 * 3.75px 0 6.93 * 3.75px;
      box-sizing: border-box;
      text-align: center;
      color: rgba(192, 196, 204, 1);
      font-size: 3.2 * 3.75px;
    }

    #index-tabbar {
      box-sizing: border-box;
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 28.53 * 3.75px;
      height: 14.4 * 3.75px;
      background: rgba(255, 255, 255, 1);

      img {
        margin: 1.87 * 3.75px auto 0;
        display: block;
        height: 6.93 * 3.75px;
        width: 6.93 * 3.75px;
      }

      .router-link-exact-active {
        span {
          color: $theme-color;
        }
      }

      a {
        height: 100%;

        span {
          text-align: center;
          font-size: 2.67 * 3.75px;
          color: rgba(192, 196, 204, 1);
          display: block;
        }
      }
    }
  }
</style>
